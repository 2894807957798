<template>
  <v-card 
    flat
    class="rounded-lg ma-1"
  >
    <v-card-title class="d-flex align-center pe-2">
      Dashboard
      <v-spacer></v-spacer>

      <div class="d-flex align-center w-50 px-2 ga-2">
        <v-text-field
          v-model="search"
          density="compact"
          label="Procurar"
          prepend-inner-icon="mdi-magnify"
          variant="solo-filled"
          flat
          hide-details
          single-line
        ></v-text-field>
        <v-select 
          v-model="loadType"
          hide-details
          density="compact"
          variant="solo-filled"
          placeholder="Filtrar Carga"
          :items="Object.values(LOAD_TYPES)"
        />
      </div>
    </v-card-title>

    <v-data-table 
      v-model:search="search" 
      :items="items"
      :headers="headers"
    >
      <template v-slot:[`item.updatedAt`]="{ item }">
        <div class="d-flex justify-center w-100">
          <v-chip
            v-if="item.updatedAt"
            variant="outlined"
            class="d-flex justify-center align-center w-50"
          >
          {{ getBrazilianFormatDate(item.updatedAt) }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.deliveryDate`]="{ item }">
        <div class="d-flex justify-center w-100">
          <v-chip
          v-if="item.deliveryDate"
          :color="getChipColor(item.deliveryDate)"
          size="default"
          variant="outlined"
          class="d-flex justify-center align-center w-50"
        >
         {{ getBrazilianFormatDate(item.deliveryDate) }}
        </v-chip>
        </div>

      </template>

      <template v-slot:[`item.loadType`]="{ item }">
        <div class="d-flex justify-center w-100">
          <v-chip 
          :color="getLoadTypeChipColor(item.loadType)" 
          class=" d-flex justify-center align-center w-50"
        >
          {{ LOAD_TYPES[item.loadType] }}
        </v-chip>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  DATE_FORMAT, 
  DASHBOARD_TABLE_HEADERS,
  LOAD_TYPES
} from '@/helpers/constants/app.constants';
import { sortList } from '@/helpers/services/app.services';

import { mapState } from 'vuex';

import dayjs from 'dayjs';

export default {
  data: () => ({
    search: '',
    loadType: null
  }),

  computed: {
    ...mapState('spot', ['spots']),

    LOAD_TYPES() {
      return LOAD_TYPES;
    },

    items() {
      return sortList(this.spots.map((spot) => {
        return {
          ...spot,
          name: `${spot.rowName}-${spot.name}`
        }
      })).filter((spot) => {
        return LOAD_TYPES[spot.loadType] === this.loadType
      })
    },

    headers() {
      return DASHBOARD_TABLE_HEADERS;
    }
  },

  mounted() {
    this.loadType = LOAD_TYPES.FINISHED;
  },

  methods: {
    getBrazilianFormatDate(date) {
      return dayjs(date).format(DATE_FORMAT.BRAZILIAN);
    },

    getLoadTypeChipColor(value) {
      if (LOAD_TYPES[value] === LOAD_TYPES.EMPTY) {
        return 'indigo';
      } else if (LOAD_TYPES[value] === LOAD_TYPES.FINISHED) {
        return 'cyan'
      } else if (LOAD_TYPES[value] === LOAD_TYPES.SEMI_FINISHED) {
        return 'teal'
      } else {
        return 'orange'
      }
    },

    getChipColor(date) {
      if (this.getBrazilianFormatDate(dayjs()) === this.getBrazilianFormatDate(date)) {
        return 'orange';
      }

      const isGreaterThan2Days = this.isDateGreaterThan(date, 2);
      const isGreaterThan3Days = this.isDateGreaterThan(date, 3);

      if (isGreaterThan3Days) {
          return 'red'
      } else if (isGreaterThan2Days) {
          return 'yellow'
      } else {
          return 'green'
      }
    },

    isDateGreaterThan(date, days) {
      const now = dayjs();
      const targetDate = dayjs(now);
      const difference = targetDate.diff(date, 'day');
    
      return difference > days;
    }
  }
}
</script>
