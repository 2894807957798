<template>
  <v-card class="pa-2">
    <v-form ref="loginForm" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="loginEmail"
            :rules="loginEmailRules"
            label="E-mail"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="loginPassword"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Password"
            :hint="passwordHin"
            counter
            @click:append="show1 = !show1"
            @keyup.enter="signIn"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6" xsm="12"> </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
          <v-btn
            v-if="!isLoading"
            block
            color="red darken-2 white--text"
            @click="signIn"
          >
            Login
          </v-btn>
          <v-progress-circular
            v-else
            :size="35"
            color="error"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  data: () => ({
    isLoading: false,
    valid: true,
    loginPassword: '',
    loginEmail: '',
    loginEmailRules: [
      (v) => !!v || 'Required',
      (v) => /.+@.+\..+/.test(v) || 'Favor inserir um e-mail válido.',
    ],
    show1: false,
    rules: {
      required: (value) => !!value || 'Obrigatório.',
      min: (v) => (v
          && v.length >= 8
          && /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(
            v,
          ))
        || 'Min 8 caracteres (1 maísculo, 1 minúsculo, 1 número, 1 caracter especial)',
    },
  }),

  computed: {
    passwordHin() {
      return 'Min 8 caracteres(1 maísculo, 1 minúsculo, 1 número, 1 caracter especial)';
    },
  },

  methods: {
    ...mapActions('user', ['login']),

    async signIn() {
      if (this.$refs.loginForm.validate()) {
        this.isLoading = true;
        const { success } = await this.login({
          email: this.loginEmail.toLowerCase(),
          password: this.loginPassword,
        });
        this.isLoading = false;

        if (success) {
          this.$router.push({ name: 'main' });
          this.$emit('login-success');
        } else {
          this.$emit('login-fail');
        }
      }
    },

    reset() {
      this.$refs.form.reset();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
  }
}
</script>