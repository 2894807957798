import api from './axios/config';

export async function signIn(payload) {
  try {
    const { data } = await api.post('auth/signin', payload);

    return { success: true, data };
  } catch (error) {
    return { success: false, error };
  }
}

export async function signUp(payload) {
  try {
    const { data } = await api.post('auth/signup', payload);

    return { success: true, data };
  } catch (error) {
    return { success: false, error };
  }
}

export async function validateTokenOnServer() {
  try {
    const { data } = await api.get('auth/validate');

    return {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      roleCode: 1,
    };
  } catch (error) {
    return false;
  }
}

export async function fetchUser() {
  try {
    const { data } = await api.get('auth/user');

    return { success: true, data };
  } catch (error) {
    return { success: false, error };
  }
}

export async function logout() {
  try {
    const { data } = await api.post('auth/logout');

    return { success: true, data };
  } catch (error) {
    return { success: false, error };
  }
}
