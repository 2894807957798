import { createSpot, updateSpotById, deleteSpotById } from "@/api/spot.api";
import { sortList } from "@/helpers/services/app.services";

export default {
  namespaced: true,

  state: {
    spots: []
  },

  mutations: {
    setSpots(state, spots) {
      state.spots = spots;
    }
  },

  actions: {
    async addSpot({state, commit}, spot) {
      const { success, data, err } = await createSpot(spot);

      if (success) {
        const spotsCopy = Array.from(state.spots);

        spotsCopy.push({
          ...spot,
          id: data.id,
          created_at: data.createdAt,
        });
  
        commit('setSpots', sortList(spotsCopy));
        return { success: true };
      } else {
        console.log(err);
        return { success: false };
      }
    },

    async updateSpot({state, commit}, spot) {
      const { success, err } = await updateSpotById(spot);

      if (success) {
        const spotsCopy = Array.from(state.spots);

        const spotIndex = spotsCopy.findIndex(spotCopy => spotCopy.name === spot.name && spotCopy.rowName === spot.rowName);
        spotsCopy[spotIndex] = { ...spot, updatedAt: new Date()};

        commit('setSpots', sortList(spotsCopy));
        return { success: true };
      } else {
        console.log(err);
        return { success: false };
      }
    },

    async deleteSpot({state, commit}, spot) {
      const { success, err } = await deleteSpotById(spot);

      if (success) {
        const spotsCopy = Array.from(state.spots);

        const spotIndex = spotsCopy.findIndex(spotCopy => spotCopy.name === spot.name && spotCopy.rowName === spot.rowName);
        spotsCopy.splice(spotIndex, 1)
  
        commit('setSpots', sortList(spotsCopy));

        return { success: true };
      } else {
        console.log(err);
        return { success: false };
      }
    }
  },
}