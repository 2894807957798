<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer">
      <NavContent />
    </v-navigation-drawer>

    <v-app-bar class="bg-blue-grey-darken-4">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-app-bar-title>Outpacking - Expedição</v-app-bar-title>
    </v-app-bar>

    <v-main>
      <MainTabs />
    </v-main>
  </v-app>
</template>

<script setup>
  import { ref } from 'vue'

  const drawer = ref(null)
</script>

<script>
import NavContent from '@/components/NavContent.vue'
import MainTabs from '@/components/MainTabs.vue'

  export default {
    components: { NavContent, MainTabs },
    data: () => ({ drawer: null }),
  }
</script>