import { fetchRows, createRow } from "@/api/row.api";
import { sortList } from "@/helpers/services/app.services";

export default {
  namespaced: true,

  state: {
    rows: []
  },

  mutations: {
    setRows(state, rows) {
      state.rows = rows;
    }
  },

  actions: {
    async initRows({commit}) {
      const { success, data, err } = await fetchRows();

      if (success) {
        commit('setRows', data);
        commit('spot/setSpots', sortList(data.flatMap(row => row.Spot)), { root: true });

        return { success: true };
      } else {
        console.log(err)

        return { success: false };
      }
    },

    async addRow({state, commit}, row) {
      const { success, err } = await createRow(row);

      if (success) {
        const rowsCopy = Array.from(state.rows);
        rowsCopy.push(row);
        commit('setRows', rowsCopy);

        return { success: true };
      } else {
        console.log(err)

        return { success: false };
      }
    }
  },
}