import { signIn, signUp, fetchUser, logout } from '@/api/auth.api';
import { USER_ROLES } from '@/helpers/constants/app.constants';

export default {
  namespaced: true,
  state: {
    user: {}
  },

  getters: {
    isAdminUser(state) {
      return state.user.role === USER_ROLES.ADMIN;
    }
  },

  mutations: {
    setUser: (state, user) => {
      state.user = user;
    }
  },

  actions: {
    async login({ commit }, payload) {
      const { success, data, error } = await signIn(payload);

      if (success && !data.status) {
        commit('setUser', data.user);
        localStorage.setItem('token', data.tokens.access_token);

        return { success: true };
      }

      return { success: false, error };
    },

    async register({ commit }, payload) {
      const { success, data, error } = await signUp(payload);

      if (success && !data.status) {
        commit('setUser', data.user);
        localStorage.setItem('token', data.tokens.access_token);

        return { success: true };
      }

      return { success: false, error };
    },

    async getUser({ commit }) {
      const { success, data, error } = await fetchUser();

      if (success) {
        commit('setUser', data);
        return { success: true };
      }

      return { success: false, error };
    },

    async logout({ commit }) {
      const { success, error } = await logout();

      if (success) {
        commit('setUser', {});
        localStorage.removeItem('token');

        return { success: true };
      } else {
        console.log(error)
      }
    }
  },
};
