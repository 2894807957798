import { createMemoryHistory, createRouter } from 'vue-router';

import MainView from '@/views/MainView.vue';
import SignPage from '@/views/SignPage.vue';

const router = new createRouter({
  history: createMemoryHistory(),

  routes: [
    {
      path: '/',
      name: 'main',
      component: MainView
    },
    {
      path: '/login',
      name: 'login',
      component: SignPage,
    },
  ],
});

export default router;
