<template>
  <v-app id="sign" class="d-flex w-100 h-100 bg-blue-grey-darken-4">
    <v-dialog 
      v-model="dialog" 
      persistent 
      max-width="600px" 
      min-width="360px"
    >
      <div class="d-flex align-center justify-center mt-n16">
        <img
          :src="require('@/assets/outpacking-logo.svg')"
          alt="Outpacking Logo"
          class="w-75"
        />
      </div>
      <v-card class="w-100">
        <v-tabs
          v-model="tab"
          icons-and-text
          grow
        >
          <v-tab :value="0">Login</v-tab>
          <v-tab :value="1">Cadastro</v-tab>
        </v-tabs>
        <v-tabs-window v-model="tab">
          <v-tabs-window-item :value="0">
            <LogIn 
              @login-success="displayLoginSuccessToast"
              @login-fail="displayLoginFailToast"
            />
          </v-tabs-window-item>
          <v-tabs-window-item :value="1">
            <SignUp 
              @signup-success="displaySignupSuccessToast"
              @signup-fail="displaySignupFailToast"
            />
          </v-tabs-window-item>
        </v-tabs-window>
    </v-card>

    </v-dialog>
    
    <v-snackbar :color="snackbarColor" v-model="snackbar" :timeout="4000">
      <div color="white">{{ snackbarText }}</div>

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import LogIn from '@/components/Sign/LogIn.vue';
import SignUp from '@/components/Sign/SignUp.vue'

export default {
  name: 'SignPage',

  components: { LogIn, SignUp },

  data: () => ({
    snackbar: false,
    snackbarText: 'Erro login in.',
    snackbarColor: 'red',
    dialog: true,
    tab: 0,
  }),

  methods: {
    displayLoginSuccessToast() {
      this.snackbarText = 'Login feito com sucesso.';
      this.snackbarColor = 'green';
      this.snackbar = true;
    },

    displayLoginFailToast() {
      this.snackbarText = 'Falha ao fazer login.';
      this.snackbarColor = 'red';
      this.snackbar = true;
    },

    displaySignupSuccessToast() {
      this.snackbarText = 'Cadastro feito com sucesso.';
      this.snackbarColor = 'green';
      this.snackbar = true;
    },

    displaySignupFailToast() {
      this.snackbarText = 'Falha ao realizar cadastro.';
      this.snackbarColor = 'red';
      this.snackbar = true;
    }
  }
};
</script>

<style scoped>
#sign {
  background: black;
}
</style>
