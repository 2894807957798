<template>
  <div class="d-flex flex-column justify-space-between h-100 bg-blue-grey-darken-4">
    <div class="d-flex justify-center w-100">
        <img
          :src="require('@/assets/outpacking-logo.svg')"
          alt="Outpacking Logo"
          class="w-75"
        />
      </div>

      <div class="d-flex flex-column align-center justify-center ga-2">
        <div class="d-flex align-center ga-1">
          <v-avatar color="error" size="small">
            <v-icon icon="mdi-account-circle"></v-icon>
          </v-avatar>
          {{ fullname }}
        </div>

        <v-btn 
          variant="tonal"
          class="bg-red-darken-4 white--text mb-2"
          @click="onLogout"
        >
          <div class="d-flex d-flex ga-1">
            <v-icon>mdi-logout</v-icon>
            Logout
          </div>
        </v-btn>
      </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState('user', ['user']),

    fullname() { 
      if (this.user.firstName) {
        return `${this.capitalizeFirstLetter(this.user?.firstName?.toLowerCase())} ${this.capitalizeFirstLetter(this.user?.lastName?.toLowerCase())}`
      }

      return '';
    }
  },

  methods: {
    ...mapActions('user', ['logout']),

    capitalizeFirstLetter(name) {
      return name.charAt(0).toUpperCase() + name.slice(1);
    },

    async onLogout() {
      const { success } = await this.logout();

      if (success) {
        this.$router.push({ name: 'login'})
      }
    }
  }
}
</script>