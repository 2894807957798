<template>
  <v-card 
    flat
    class="rounded-lg ma-1"
  >
    <v-card-title class="d-flex align-center pe-2 ga-1">
      Espaço
      
      <SpotDialog 
        :spot-obj="spotToEdit"
        @close="onCloseSpotDialog"  
      />
      
      <v-spacer></v-spacer>

      <div class="d-flex align-center w-50 px-2 ga-2">
        <v-text-field
          v-model="search"
          density="compact"
          label="Procurar"
          prepend-inner-icon="mdi-magnify"
          variant="solo-filled"
          flat
          hide-details
          single-line
        ></v-text-field>
        <v-select 
          v-model="loadType"
          hide-details
          density="compact"
          variant="solo-filled"
          placeholder="Filtrar Carga"
          :items="Object.values(LOAD_TYPES)"
        />
      </div>

    </v-card-title>

    <v-divider></v-divider>
    <v-data-table 
      v-model:search="search" 
      :items="items"
      :headers="headers"
    > 
      <template v-slot:[`item.loadType`]="{ item }">
        <v-chip 
          :color="getChipColor(item.loadType)" 
          class=" d-flex justify-center align-center w-75"
        >
          {{ LOAD_TYPES[item.loadType] }}
        </v-chip>
        
      </template>

      <template v-slot:[`item.deliveryDate`]="{ item }">
        <div class="d-flex justify-center">
          <v-chip
          v-if="item.deliveryDate"
          :color="getDateChipColor(item.deliveryDate)"
          size="default"
          variant="outlined"
          class="d-flex justify-center align-center"
        >
         {{ getBrazilianFormatDate(item.deliveryDate) }}
        </v-chip>
        </div>

      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="updateSpot(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="isAdminUser"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>

        <v-dialog 
          v-model="dialogDelete" 
          max-width="500px"
        >
          <v-card>
            <v-card-title class="text-h5">
              {{`Tem certeza que quer deletar Local ${spotForDeletion?.name || ''}?`}}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                text
                :disabled="isLoading"
                color="blue darken-1" 
                @click="closeDelete">Cancel</v-btn>
              <v-btn
                v-if="!isLoading"
                text 
                color="blue darken-1" 
                @click="confirmedDeletion">
                  OK
              </v-btn>
              <v-progress-circular
                v-else
                :size="35"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>

    <v-snackbar :color="snackbar.color" v-model="snackbar.active" :timeout="4000">
      <div color="white">{{ snackbar.text }}</div>

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.active = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import SpotDialog from '@/components/Spot/SpotDialog';

import { SPOT_TABLE_HEADERS, LOAD_TYPES, DATE_FORMAT } from '@/helpers/constants/app.constants';
import { sortList } from '@/helpers/services/app.services';

import { mapActions, mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';

export default {
  components: { SpotDialog },
  
  data: () => ({
    isLoading: false,
    search: '',
    spotToEdit: {},
    spotForDeletion: '',
    dialogDelete: false,
    loadType: null,
    snackbar: {
      active: false,
      color: 'green',
      text: 'Local deletado com sucesso.'
    }
  }),

  computed: {
    ...mapState('spot', ['spots']),
    ...mapGetters('user', ['isAdminUser']),

    LOAD_TYPES() {
      return LOAD_TYPES;
    },

    headers() {
      return SPOT_TABLE_HEADERS;
    },

    items() {
      return sortList(this.spots.map((spot) => {
        return {
          ...spot,
          name: `${spot.rowName}-${spot.name}`
        }
      })).filter((spot) => {
        return LOAD_TYPES[spot.loadType] === this.loadType
      })
    }
  },

  mounted() {
    this.loadType = LOAD_TYPES.EMPTY;
  },

  methods: {
    ...mapActions('spot', ['deleteSpot']),

    getBrazilianFormatDate(date) {
      return dayjs(date).format(DATE_FORMAT.BRAZILIAN);
    },

    getDateChipColor(date) {
      if (this.getBrazilianFormatDate(dayjs()) === this.getBrazilianFormatDate(date)) {
        return 'orange';
      }

      const isGreaterThan2Days = this.isDateGreaterThan(date, 2);
      const isGreaterThan3Days = this.isDateGreaterThan(date, 3);

      if (isGreaterThan3Days) {
          return 'red'
      } else if (isGreaterThan2Days) {
          return 'yellow'
      } else {
          return 'green'
      }
    },

    isDateGreaterThan(date, days) {
      const now = dayjs();
      const targetDate = dayjs(now);
      const difference = targetDate.diff(date, 'day');

      return difference > days;
    },

    getLoadTypeKey(value) {
      return Object.keys(this.LOAD_TYPES).find(key => this.LOAD_TYPES[key] === value);
    },

    getChipColor(value) {
      if (LOAD_TYPES[value] === LOAD_TYPES.EMPTY) {
        return 'indigo';
      } else if (LOAD_TYPES[value] === LOAD_TYPES.FINISHED) {
        return 'cyan'
      } else if (LOAD_TYPES[value] === LOAD_TYPES.SEMI_FINISHED) {
        return 'teal'
      } else {
        return 'orange'
      }
    },

    updateSpot(item) {
      this.spotToEdit = item;
    },

    onCloseSpotDialog(snackbar) {
      this.snackbar = snackbar;
      this.spotToEdit = {};
    },

    deleteItem (item) {
      this.spotForDeletion = item
      this.dialogDelete = true
    },

    async confirmedDeletion() {
      this.isLoading = true;
      const { success } = await this.deleteSpot(JSON.parse(JSON.stringify(this.spotForDeletion)))
      this.isLoading = false;

      if (success) {
        this.snackbar.text = 'Local deletado com sucesso.'
        this.snackbar.color = 'green';
        this.snackbar.active = true;
      } else {
        this.snackbar.text = 'Erro ao deletar local.',
        this.snackbar.color = 'red';
        this.snackbar.active = true;
      }

      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.spotForDeletion = null;
    }
  }
}
</script>